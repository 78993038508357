a {
  color: unset !important;
}

p {
  color: #666;
  line-height: 26px;
}

input[type="checkbox" i] {
  cursor: pointer;
}

.fas {
  transition: all .5s ease-in-out;
}

.row-m {
  margin-left: -1rem;
}

h3.what-we-do__title {
  font-size: 1.25rem;
}

h4.footer__title {
  font-size: 1.35rem;
}

h4.docs {
  font-size: 1.1rem;
}

.text-sm {
  font-size: .9rem;
}

.text-xsm {
  font-size: .8rem;
}

.link {
  outline: none;
  text-decoration: none;
  transition: all .3s ease-in-out;
}

.link:hover, .link:active {
  color: #202020;
}

.brown {
  color: #795548;
}

.tt-upper {
  text-transform: uppercase;
}

.ff-montserrat {
  font-family: montserrat, sans-serif;
}

.ff-poppins {
  font-family: Poppins, sans-serif;
}

.fs-7 {
  font-size: 1.3rem;
}

.fs-0 {
  font-size: .9rem;
}

.fs-tiny {
  font-size: .8rem;
}

.fs-very-tiny {
  font-size: .7rem;
}

.fa-graduation-cap {
  color: #fff;
}

.go-to-top {
  width: 40px;
  height: 40px;
  z-index: 500;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #795548;
  border-radius: 2px;
  font-size: 22px;
  line-height: 40px;
  transition: all .5s ease-in-out;
  position: fixed;
  bottom: -40px;
  right: 20px;
}

.fa-angle-up {
  color: #fff;
}

.form-control {
  border-radius: 0 !important;
}

.form-control:focus {
  color: #212529 !important;
  box-shadow: none !important;
  background-color: #fff !important;
  border-color: #795548 !important;
  outline: 0 !important;
}

/*# sourceMappingURL=utils.b57e10dd.css.map */
